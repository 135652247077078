<template>
  <div class="home">
    <div class="allnr">
      <div class="gonggao">
        <a class="ggtile">
          <van-image round width="40px" height="40px"
            src="http://yanxuan.nosdn.127.net/40e3ef4ecaf28f2b63e198df8dc8aa70.PNG" />
        </a>
        <div class="name">王者荣耀战力查询</div>
        <a class="ggnr">
          该系统用于查询王者荣耀某英雄最低上榜战力地区,方便想拿标的同学,更改战区地址拿标~
        </a>
        <br />
        <a class="alink" href="https://mp.weixin.qq.com/s/prTIWFMlw9tRHemkeATu_A" target="_blank">王者荣耀改战区教程>>></a>
        <br />
        <!-- <a class="alink" href="https://ukzs.net" target="_blank"
          >更多好玩软件下载>>></a
        > -->

        <van-field style="margin-top: 20px" is-link readonly clickable label="英雄" :value="value" :placeholder="hero"
          @click="showPickerHero = true" />

        <div class="type">
          <van-radio-group v-model="type" direction="horizontal">
            <van-radio name="ios_wx">iOS 微信平台</van-radio>
            <van-radio name="ios_qq">iOS QQ平台</van-radio>
            <van-radio name="qq">安卓 QQ平台</van-radio>
            <van-radio name="wx">安卓 微信平台</van-radio>
          </van-radio-group>
        </div>
        <!-- 选择英雄 -->
        <van-popup v-model="showPickerHero" round closeable position="bottom" :style="{ height: '90%' }">
          <div class="popupTitle">选择要查询的英雄</div>
          <div v-if="!heroList" class="lad">
            <van-loading size="40px">加载中...</van-loading>
          </div>
          <div v-if="heroList" class="herorow">
            <van-row gutter="20">
              <van-col span="6" v-for="(item, index) in heroList" :key="index" @click="clickHero(item.cname)">
                <img class="heroIcon" :src="item.iconUrl" />
                <div class="heroName">{{ item.cname }}</div>
              </van-col>
            </van-row>
          </div>
        </van-popup>
        <!-- 展示结果 -->
        <van-popup v-model="showResult" round closeable :style="{ width: '80%' }">
          <div class="resultTop">
            <img class="photo" :src="result.photo" />
            <div class="alias">{{ result.alias }}</div>
          </div>
          <div class="power">
            <div class="area">
              最低县标地区:
              <van-tag color="#ffe1e1" text-color="#ad0000" size="large">
                {{ result.area }}
              </van-tag>
            </div>
            <div class="areaPower">
              {{ result.area }}上榜站力:
              <van-tag color="#ffe1e1" text-color="#ad0000" size="large">
                {{ result.areaPower }}
              </van-tag>
            </div>

            <div class="area">
              最低市标地区:
              <van-tag color="#ffe1e1" text-color="#ad0000" size="large">
                {{ result.city }}
              </van-tag>
            </div>
            <div class="areaPower">
              {{ result.city }}上榜站力:
              <van-tag color="#ffe1e1" text-color="#ad0000" size="large">
                {{ result.cityPower }}
              </van-tag>
            </div>

            <div class="area">
              最低省标地区:
              <van-tag color="#ffe1e1" text-color="#ad0000" size="large">
                {{ result.province }}
              </van-tag>
            </div>
            <div class="areaPower">
              {{ result.province }}上榜站力:
              <van-tag color="#ffe1e1" text-color="#ad0000" size="large">
                {{ result.provincePower }}
              </van-tag>
            </div>
            <div class="updateTime">数据更新时间:{{ result.updatetime }}</div>
          </div>
        </van-popup>

        <div class="get" style="text-align: center; margin-top: 18px">
          <van-button round type="info" size="large" color="#ffd803" @click="goaz">查询</van-button>
        </div>
      </div>
    </div>
    <div class="footer">
      <p class="text-inline" style="text-align: center">
        2016 - 2022 知网少年©️版权所有 盗版必究
      </p>
    </div>

    <div class="seprow" v-if="prog">
      <van-loading color="#1989fa" />
      <br />
      <van-progress :percentage="percentage" pivot-color="#7232dd" pivot-text="请求中" stroke-width="8"
        color="linear-gradient(to right, #be99ff, #7232dd)" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: "",
      hintShow: true,
      load: true,
      showPickerHero: false,
      heroList: [],
      percentage: "20",
      hero: "请选择英雄",
      type: "ios_wx",
      showResult: false,
      result: [],
      percentage: "",
      prog: false,
    };
  },
  created() {
    this.queryHeroList();
    // this.$dialog
    //   .alert({
    //     title: "提示",
    //     message: "当前使用人数过多,请稍后再来!",
    //     theme: "round-button",
    //   })
    //   .then(() => {});
  },
  methods: {
    queryHeroList() {
      var that = this;
      that.$http.get("/api/herolist.json").then(function (Res) {
        console.log(Res);
        that.heroList = Res.data.data;
      });
    },
    clickHero(e) {
      console.log(e);
      this.showPickerHero = false;
      this.hero = e;
    },
    goaz() {
      var that = this;

      if (that.hero == "请选择英雄") {
        alert("尚未选择英雄,请选择!");
      } else {
        that.prog = true;
        that.percentage = 30;

        that.time = setInterval(() => {
          that.percentage = that.percentage + 10;
          if (that.percentage == "90") {
            clearInterval(that.time);
          }
        }, 1000);
        that.$http
          .get("/api/getHeroInfo.php", {
            params: {
              hero: that.hero,
              type: that.type,
            },
          })
          .then(function (res) {
            // console.log(res);
            if ((res.data.code = "200")) {
              that.percentage = "100";
              clearInterval(that.time);
              that.prog = false;
              that.result = res.data.data;
              that.showResult = true;
            }
          });
      }
    },
  },
};
</script>
<style scoped>
.lad {
  margin-top: 60px;
  text-align: center;
}

.van-cell {
  background-color: #f4f4f4;
  border-radius: 8px;
}

.popupTitle {
  margin-top: 20px;
  text-align: center;
  font-weight: 500;
}

.herorow {
  width: 90%;
  padding: 40px 18px;
  background-color: #fff;
  margin-top: 20px;
}

.heroIcon {
  width: 70px;
  height: 70px;
  border-radius: 8px;
}

.heroName {
  width: 70px;
  text-align: center;
  font-weight: 500;
  color: #000;
}

.type {
  margin-top: 0px;
}

.van-radio--horizontal {
  margin-top: 20px;
}

.resultTop {
  padding: 40px 20px;
  background-color: #fff;
  margin-top: 20px;
}

.photo {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  float: left;
}

.alias {
  padding: 0 20px;
  height: 50px;
  line-height: 50px;
  float: left;
  color: #001858;
  font-weight: 500;
}

.power {
  padding: 40px 20px;
  color: #010101;
  font-size: 14px;
  font-weight: 500;
}

.area {
  margin-top: 10px;
}

.updateTime {
  margin-top: 20px;
  color: #ff8e3c;
}

.allnr {
  width: 100%;
  height: auto;
  display: block;
  max-width: 800px;
  margin-top: 80px;
  margin-left: auto;
  margin-right: auto;
}

.gonggao {
  width: auto;
  height: auto;
  margin-left: 20px;
  margin-right: 20px;
  background-color: #ffffff;
  border-radius: 20px;
  padding: 25px;
  margin-bottom: 20px;
}

.ggtile {
  font-size: 18px;
  color: #000000;
  font-weight: 900;
  display: block;
  margin-top: 0px;
  float: left;
}

.name {
  margin-top: 8px;
  margin-left: 60px;
  color: #000000;
  font-weight: bold;
}

.get {
  text-align: center;
  margin-top: 38px;
}

.get .van-button__text {
  font-size: 14px;
  color: #272343;
  font-weight: bold;
}

.ggnr {
  color: #616264;
  font-weight: normal;
  font-size: 14px;
  margin-top: 40px;
  display: block;
}

.wechat {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  width: 70%;
  height: auto;
}

.back {
  font-family: -apple-system-font, "Helvetica Neue", sans-serif;
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: #000000;
  color: ffffff;
  margin-left: 20px;
  margin-right: 20px;
  height: 50px;
  line-height: 50px;
  width: auto;
  display: block;
  border-radius: 10px;
  text-decoration: none;
  text-align: center;
}

.span {
  color: red;
  font-weight: bold;
}

.jimg {
  width: 60%;
  margin-top: 40%;
  margin-left: 20%;
}

.jimg img {
  width: 100%;
  height: 100%;
}

.footer {
  font-size: 14px;
  margin-top: auto;
  padding: 20px 0;
  width: 100%;
  color: #666;
  position: fixed;
  bottom: 0;
  text-align: center;
}

.footer {
  font-size: 14px;
  margin-top: auto;
  padding: 20px 0;
  width: 100%;
  color: #666;
  text-align: center;
}

.seprow {
  width: auto;
  height: auto;
  margin-left: 20px;
  margin-right: 20px;
  background-color: #ffffff;
  border-radius: 12px;
  padding: 6.66%;
  margin-bottom: 20px;
  text-align: center;
}
</style>